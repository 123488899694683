// You can add global styles to this file, and also import other style files.

// This gives you the GSDS classes and vars to use in your app, such as colors and microinteractions
@import '@gs-ux-uitoolkit-react/design-system/style/design-system';

// This gives you the utility classes such as 'mx-auto' and 'p-2'
// @import '@gs-ux-uitoolkit-react/design-system/style/utilities';

// If other components use sass for their styling, import them below...
// @import '@gs-ux-uitoolkit-react/tree/style/main'

.gs-uitk-c-7guv2f--table-table {
  width: unset !important;;
}

.gs-uitk-c-cbm6l7 gs-table__table {
  width: unset !important;
}

.gs-uitk-c-cbm6l7 {
  width: unset !important;
}

.gs-uitk-c-dtwxp9--button-button--button-override-button:disabled {
  opacity: unset;
}

.gs-uitk-c-1fmws1i--menu-root {
  margin-top: 135px !important;
  margin-left: 40px !important;
}

.approvalHeading {
  margin-bottom: 8px;
}

.queryGridListGroup {
  margin-block-start: 0em;
  overflow-x: hidden;
  padding-bottom: 16px;
}

.teams-table-cell-standard {
  padding-left: 2px;
  padding-right: 2px;
}

li[data-gs-uitk-component="list-group-item"] {
  background: inherit;
}
